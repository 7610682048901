import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"

import { Provider } from "react-redux"

import store from "./redux/store"

import { ThemeProvider } from "@material-ui/core/styles"
import theme from "./themeConfig"
import Routes from "./Routes"

import Loader from "./components/Loader"

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<Suspense fallback={<Loader />}>
				<Routes />
			</Suspense>
		</ThemeProvider>
	</Provider>,
	document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
